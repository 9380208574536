import * as React from 'react';

import Layout from '../components/templates/Layout';
import Seo from '../components/parts/Seo';
import ContentBlock from '../components/molecules/ContentBlock';

const NotFoundPage = () => (
  <Layout pageLocation="/404/">
    <Seo pageTitle="404 Not found" />
    <ContentBlock title="404">
      <p style={{ textAlign: 'center' }}>NOT FOUND</p>
    </ContentBlock>
  </Layout>
);

export default NotFoundPage;
